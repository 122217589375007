import http from "@/services/http";
import moment from "moment-timezone";

const lithuanianTimeZone = "Europe/Vilnius";

var fetchHolidays = async (context, payload) => {
  context.commit("setLoading", true);

  var { data, error } = await http.getLeavesAdmin();
  if (error || !data) {
    return false;
  }
  data = data.filter((i) => i.calander_type == "leaves");
  context.commit("fetchHolidays", data);
  context.commit("setLoading", false);

  return true;
};
var fetchCalander = async (context) => {
  context.commit("setLoading", true);

  var { data, error } = await http.getLeavesAdmin(true);
  if (error || !data) {
    return false;
  }
  context.commit("fetchCalander", data);
  context.commit("setLoading", false);

  return true;
};

var fetchToday = async (context, payload) => {
  context.commit("fetchToday", payload);
};
var calendarShowMore = async (context, payload) => {
  context.commit("calendarShowMore", payload);
};
var fetchMyHolidays = async (context, payload) => {
  context.commit("setLoading", true);

  var { data, error } = await http.getMyLeaves();
  if (error || !data) {
    return false;
  }
  context.commit("fetchHolidays", data);
  context.commit("setLoading", false);

  return true;
};

var fetchMyLeaves = async (conext, payload) => {
  var { data, error } = await http.fetchLeaves();
  if (error || !data) {
    return false;
  }
  conext.commit("fetchMyLeaves", data);
  return true;
};

var replyHoliday = async (context, payload) => {
  var { data, error } = await http.ReplytLeave(payload);
  if (error || !data) {
    return false;
  }
  context.commit("replyHoliday", data.leave);
  context.commit("sendMessage", data.message);
  return true;
};

var showReply = async (context, payload) => {
  context.commit("showReply", payload);
};

var requestHoliday = async (context, payload) => {

  payload.startDate = moment(payload.date[0]).hours(12);
  payload.endDate = moment(payload.date[1]).hours(12);

  payload.startDate = moment(payload.startDate.tz(lithuanianTimeZone));
  payload.endDate = moment(payload.endDate.tz(lithuanianTimeZone));

  console.log("🚀 ~ requestHoliday ~  payload.startDate=>", payload.startDate.toString());
  console.log("🚀 ~ requestHoliday ~  payload.endDate=>", payload.endDate.toString());

  var { data, error } = await http.requestLeave(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  if (data.newLeave?._id) {
    context.commit("requestHoliday", data.newLeave);
  }
  if (data.newUnpaidLeave?._id) {
    context.commit("requestHoliday", data.newUnpaidLeave);
  }
  context.commit("sendMessage", data.message);
  return true;
};

var RemoveHolidays = (context) => {
  context.commit("RemoveHolidays");
};

var updateLeave = async (context, payload) => {
  payload.startDate = moment(payload.startDate).tz(lithuanianTimeZone);
  payload.endDate = moment(payload.endDate).tz(lithuanianTimeZone);

  var { data, error } = await http.UpdateLeave(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("updateLeave", data.newLeave);
  context.commit("sendMessage", data.message);
  return true;
};

var fetchReport = async (context, payload) => {
  var startDate = moment(payload[0]).format("DD/MM/YYYY");
  var endDate = moment(payload[1]).format("DD/MM/YYYY");

  var { data, error } = await http.FetchReportLeave({ startDate, endDate });
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  console.log(data);
  context.commit("sendMessage", "Report has been downloaded !");
  return data;
};

var fetchConflict = async (context, payload) => {
  var startDate = moment(payload[0]).format("DD/MM/YYYY");
  var endDate = moment(payload[1]).format("DD/MM/YYYY");

  var { data, error } = await http.fetchConflictAPI({ startDate, endDate });
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  console.log(data);
  context.commit("setHolidayConflict", data.conflict);
  return data;
};
export const HolidaysActions = {
  fetchHolidays,
  replyHoliday,
  showReply,
  fetchMyHolidays,
  requestHoliday,
  fetchCalander,
  RemoveHolidays,
  updateLeave,
  fetchReport,
  fetchMyLeaves,
  fetchToday,
  calendarShowMore,
  fetchConflict,
};