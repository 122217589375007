import moment from "moment";
import { NCard, NGi, NGrid, NH2, NTag, NTooltip, NIcon } from "naive-ui";
import { h } from "vue";
import {
  AddCircleOutline,
  Information,
  InformationCircle,
  InformationCircleSharp,
} from "@vicons/ionicons5";

const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};

function calculateDaysLeft(sinceDate) {
  const givenDate = new Date(sinceDate);
  const currentDate = new Date();

  // Add 30 days to the given date
  const deletionDate = new Date(givenDate);
  deletionDate.setDate(deletionDate.getDate() + 30);

  // Calculate the difference in milliseconds between the deletion date and the current date
  const differenceInTime = deletionDate.getTime() - currentDate.getTime();

  // Convert the difference in milliseconds to days
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  // Return the difference in days, capped at 30 days
  return Math.min(differenceInDays, 30);
}

function capitalizeFirstLetter(str) {
  return str?.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export default {
  components: {
    AddCircleOutline,
    NTooltip,
  },
  methods: {
    calculateDaysLeft,
    capitalizeFirstLetter,
  },
  usersTable: [
    {
      type: "expand",
      expandable: (rowData) => (rowData.deletedAt ? false : true),
      renderExpand: (rowData) => {
        return (
          <NCard>
            <NGrid x-gap={12} cols={3}>
              <NGi>
                <b>Start Date</b>
              </NGi>
              <NGi>
                <b>Birthday</b>
              </NGi>
            </NGrid>
            <NGrid x-gap={12} cols={3}>
              <NGi>{moment(rowData.startDate).format("DD/MM/YYYY") || "-"}</NGi>
              <NGi>{moment(rowData.birthday).format("DD/MM/YYYY") || "-"}</NGi>
            </NGrid>
          </NCard>
        );
      },
    },
  
    {
      title: "First Name",
      key: "firstName",
      render: (row) => {
        let a = "";
        return (
          <span
            style={{
              color: row.deletedAt ? "red" : "",
              display: "flex",
            }}
          >
            {capitalizeFirstLetter(row.firstName)}
            <span v-show={row.deletedAt} content="Tooltip Text">
              <NTooltip>
                {{
                  trigger: (
                    <InformationCircleSharp
                      style={"width:15px;color:red;margin-left:2px"}
                    />
                  ),
                  default: () => (
                    <div>({calculateDaysLeft(row.deletedAt)} days left)</div>
                  ),
                }}
              </NTooltip>
            </span>
          </span>
        );
      },
    },
    {
      title: "Last Name",
      key: "lastName",
      render: (row) => {
        return (
          <span
            style={{
              color: row.deletedAt ? "red" : "",
            }}
          >
            {capitalizeFirstLetter(row.lastName)}
          </span>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      render: (row) => {
        return (
          <span
            style={{
              color: row.deletedAt ? "red" : "",
            }}
          >
            {row.email}
          </span>
        );
      },
    },
    {
      title: "Position",
      key: "job",
      render: (row) => {
        return (
          <span
            style={{
              color: row.deletedAt ? "red" : "",
            }}
          >
            {capitalizeFirstLetter(row.job)}
          </span>
        );
      },
    },
  ],
  adminHolidays: [
    {
      title: "Employee",
      key: "user",
    },
    {
      title: "Type",
      key: "type",
      render: (row) => {
        return (
          <span v-if={row.type}>
            <NTooltip>
              {{
                trigger: capitalizeFirstLetter(row.type),
                default: () => (
                  <div>
                    Description :<b>{row?.description}</b>
                  </div>
                ),
              }}
            </NTooltip>
          </span>
        );
      },
    },
    {
      title: "Start",
      key: "startDate",
      render: (row) => {
        return <span> {moment(row.startDate).format("DD-MM-YYYY")} </span>;
      },
    },
    {
      title: "End",
      key: "endDate",
      render: (row) => {
        return <span> {moment(row.endDate).format("DD-MM-YYYY")} </span>;
      },
    },
    {
      title: "Request date",
      key: "request-date",
      render: (row) => {
        return (
          <span
          v-if={row.requestedAt.length !== 0}
          >
            {moment(row?.requestedAt).format("DD-MM-YYYY")}
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (row) => {
        console.log("row", row);
        return row.status !== "pending" && row.comment.length
          ? h(
              NTag,
              {
                type:
                  row?.status === "pending"
                    ? "warning"
                    : row?.status === "accepted"
                    ? "success"
                    : "error",
              },
              <span v-if={row.status !== "pending"}>
                <NTooltip>
                  {{
                    trigger: capitalizeFirstLetter(row?.status),
                    default: () => (
                      <div>
                        <b>
                          Comment By Admin :<p> {row?.comment} </p>
                        </b>
                      </div>
                    ),
                  }}
                </NTooltip>
              </span>
            )
          : h(
              NTag,
              {
                type:
                  row.status === "pending"
                    ? "warning"
                    : row.status === "accepted"
                    ? "success"
                    : "error",
              },
              capitalizeFirstLetter(row.status)
            );
      },
    },
  ],
  employeeHolidays: [
    {
      key: "type",
      render: (row) => {
        return (
          <span v-if={row.type}>
            <NTooltip>
              {{
                trigger: capitalizeFirstLetter(row?.type),
                default: () => (
                  <div>
                    Description :<b>{row?.description}</b>
                  </div>
                ),
              }}
            </NTooltip>
          </span>
        );
      },
    },
    {
      title: "Start",
      key: "startDate",
      render: (row) => {
        return <span> {moment(row.startDate).format("DD-MM-YYYY")} </span>;
      },
    },
    {
      title: "End",
      key: "endDate",
      render: (row) => {
        return <span> {moment(row.endDate).format("DD-MM-YYYY")} </span>;
      },
      
    },
    {
      title: "Status",
      key: "status",
      render: (row) => {
        return row.status !== "pending" && row.comment.length
          ? h(
              NTag,
              {
                type:
                  row?.status === "pending"
                    ? "warning"
                    : row.status === "accepted"
                    ? "success"
                    : "error",
              },
              <span v-if={row.status}>
                <NTooltip>
                  {{
                    trigger: capitalizeFirstLetter(row?.status),
                    default: () => (
                      <div>
                        Comment by :
                        <b>
                          {capitalizeFirstLetter(row?.admin?.firstName)}{" "}
                          {capitalizeFirstLetter(row?.admin?.lastName)}
                          <p> {row?.comment} </p>
                        </b>
                      </div>
                    ),
                  }}
                </NTooltip>
              </span>
            )
          : h(
              NTag,
              {
                type:
                  row.status === "pending"
                    ? "warning"
                    : row.status === "accepted"
                    ? "success"
                    : "error",
              },
              capitalizeFirstLetter(row.status)
            );
      },
    },
  ],
};

export const initialJobOptions = [
  { label: "Javascript Developer", value: "Javascript developer" },
  { label: "Team Leader", value: "team leader" },
  { label: "E-commerce Manager", value: "E-commerce Manager" },
  { label: "Online Sales Specialist", value: "Online Sales Specialist" },
  {
    label: "Digital Marketing Manager",
    value: "Digital Marketing Manager",
  },
  { label: "E-commerce Analyst", value: "E-commerce Analyst" },
  { label: "E-commerce Merchandiser", value: "E-commerce Merchandiser" },
  {
    label: "Customer Experience Manager",
    value: "Customer Experience Manager ",
  },
  {
    label: "E-commerce Operations Coordinator",
    value: "E-commerce Operations Coordinator",
  },
  {
    label: "E-commerce Content Writer",
    value: "E-commerce Content Writer",
  },
  {
    label: "E-commerce SEO Specialist",
    value: "E-commerce SEO Specialist",
  },
  {
    label: "Conversion Rate Optimization Specialist",
    value: "Conversion Rate Optimization  Specialist",
  },
];
